import React from 'react';
import twitterLogo from "./assets/twitter-logo.png";
import discordLogo from "./assets/discord-logo.png"

import { Link } from "react-router-dom";

import "./Footer.css"

export function Footer(props) {
    return (
        <div>
        <div className='footer'>
            <div className='left-footer'>
                <h1 style={{color:'white', fontFamily: 'SFProMedium', margin: 0, letterSpacing: '6px'}}> CHERRYPICK</h1>
                {/* <div className='social-media'>
                    <img src={twitterLogo} />
                    <img src={discordLogo} />
                </div> */}
            </div>
            <div className='right-footer'>
                <div className='left-column'>
                    <p className='header'> PRODUCT</p>
                    <a className='underline-on-hover' href={'https://app.cherry-pick.xyz/'}>On-chain metrics</a>
                    <a className='underline-on-hover' href={'https://app.cherry-pick.xyz/explore'}>Wallet Explorer</a>
                    <a className='underline-on-hover' href={'https://app.cherry-pick.xyz/leaderboard'}>Leaderboard</a>
                    <a className='underline-on-hover' href={'https://app.cherry-pick.xyz/token-tracker'}>Token Tracker</a>
                </div>
                <div className='right-column'>
                    <p className='header'> ADDITIONAL </p>
                    {/* <a>Terms and Conditions</a> */}
                    {/* <a>Privacy Policy</a> */}
                    <Link className='underline-on-hover' to={'./terms-and-conditions'}> Terms and Conditions </Link>
                    <Link className='underline-on-hover' to={'./privacy-policy'}> Privacy Policy </Link>
                    <Link className='underline-on-hover' to={'./cookie-policy'}> Cookie Policy </Link>
                    <p className='header' style={{marginTop: '30px'}}> CONTACT US </p>
                    <a>info@cherry-pick.xyz</a>
                </div>
            </div>
        </div>
        <div className='footer' style={{paddingTop: '50px', paddingBottom: '100px'}}>
            <div className='left-footer'>
                <p className='text' >© 2024 Cherrypick, all rights reserved</p>
            </div>
            {/* <p className='text' >© 2022 Cherrypick, all rights reserved</p> */}
            <div className='social-media'>
             {/* style={{marginLeft: 'auto'}}> */}
                    <img src={twitterLogo} onClick={()=> window.open("https://twitter.com/cherrypick_xyz", "_blank")} style={{cursor: 'pointer'}} />
                    <img src={discordLogo} onClick={()=> window.open("https://discord.gg/Uyk4F337nR", "_blank")} style={{cursor: 'pointer'}} />
            </div>
        </div>
        {/* <div className='footer' style={{paddingTop: '0px', paddingBottom: '100px'}}>
            <div className='left-footer'>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>Sole Proprietor: Elizaveta Dmitrievna Lazareva</p>
                <p className='text' style={{fontSize: '12px', color: 'gray'}}>TIN (Taxpayer Identification Number): 773461536579</p>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>PSRNSP (Primary State Registration Number of the Sole Proprietor): 31777 4600 5107 40</p>
                <p className='text' style={{fontSize: '12px', color: 'gray'}} >Address: 123060, Moscow, Marshal Sokolovsky Street, Building 10, Building 1, Apartment 23</p>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>Contact Information: +7 903 008 82 18</p>
            </div>
        </div> */}
        <div className='footer' style={{paddingTop: '0px', paddingBottom: '100px'}}>
            <div className='left-footer'>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>Registered as a Sole Professional under Freelance License in Dubai</p>
                <p className='text' style={{fontSize: '12px', color: 'gray'}}>License No. 104807</p>
                <p className='text' style={{fontSize: '12px', color: 'gray'}}>Name: Artem Shafarostov</p>
                <p className='text' style={{fontSize: '12px', color: 'gray'}} >Legal Status: Sole Professional</p>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>Business Unit: Dubai Internet City</p>
                <p className='text' style={{marginBottom: '16px', fontSize: '12px', color: 'gray'}}>Address: Ground Floor, Building 16, Dubai Internet City, Dubai, United Arab Emirates</p>
            </div>
        </div>
        </div>
    )
}