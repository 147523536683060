import React from 'react';
import './BoxSlider.css';

function importAll(r) {
    let images = [];
    r.keys().forEach((item, index) => { images.push(r(item)); });
    return images;
}

const images = importAll(require.context('./assets/tokenLogos', false, /\.(png|jpe?g|svg)$/));


const BoxSlider = () => {
  return (
    <div className="slider">
	    <div className="slide-track">
			{images.map((item) => {return (<div className="slide"> <img src={item} /> </div>)})}
			{images.map((item) => {return (<div className="slide"> <img src={item} /> </div>)})}
		</div>
    </div>
  );
};

export default BoxSlider;