import React from 'react';
import './Roadmap.css'


export function Roadmap(props) {
    const milestones = [
        'Q1: Launch new product line',
        'Q2: Expand to international markets',
        'Q3: Update branding and website',
        'Q4: Develop new partnerships',
      ];

    return (
        <ul class="timeline">
        <li>
            <div class="direction-r">
                {/* <div>Q4 2022</div> */}
                <div class="flag-wrapper">
                    {/* <span class="flag gradientColor2" style={{marginTop: '40px'}}> Now </span> */}
                    {/* <span class="time-wrapper"><span class="time">Q4 2022</span></span> */}
                    <span class="flag gradientColor2" style={{marginTop: '40px'}}> Infancy </span>
                </div>
                <div className='time'>Q4 2023</div>
                <div class="desc">
                    <li>
                        Smart Money Leaderboard
                    </li>
                    <li> Wallet Explorer </li>
                    <li> Leading on-chain market indicators </li>
                    <li> Support 40 most popular ERC-20 </li>
                    {/* <li> Support ARB token</li> */}
                    {/* My current employment. Way better than the position before! */}
                </div>
            </div>
        </li>
      
        <li>
            <div class="direction-l">
                <div class="flag-wrapper">
                    {/* <span class="flag gradientColor2">Infancy</span> */}
                    <span class="flag gradientColor2">Childhood</span>
                    {/* <span class="time-wrapper"><span class="time">2011 - 2013</span></span> */}
                </div>
                <div className='time'>Q1 2024</div>
                <div class="desc">
                    {/* <li>
                        DeX Analytics
                    </li>
                    <li> Support 60+ most popular ERC-20 </li>
                    <li> Simple alert system </li> */}
                    <li>Expanded token coverage to 80+</li>
                    <li>Introduced Wallet Search Tool</li>
                    <li>Launched SOPR Heartbeat for Pro</li>
                    {/* My current employment. Way better than the position before! */}
                </div>
            </div>
        </li>
    
        <li>
            <div class="direction-r">
                <div class="flag-wrapper">
                    {/* <span class="flag gradientColor2">Childhood</span> */}
                    <span class="flag gradientColor2">Now</span>
                    {/* <span class="time-wrapper"><span class="time">2008 - 2011</span></span> */}
                </div>
                <div className='time'>Q2 2024</div>
                <div class="desc">
                    <li>Expanded token coverage to 400+</li>
                    <li>Enhanced token tracker support to 400+</li>
                    
                    {/* <li>
                        Undervalued token leaderboard via Smart Money flows
                    </li>
                    <li> Support ETH/BTC  </li>
                    <li> Support 100+ most popular ERC-20 </li> */}
                </div>
            </div>
        </li>

        <li>
            <div class="direction-l">
                <div class="flag-wrapper">
                    <span class="flag gradientColor2">Adolescence</span>
                    {/* <span class="time-wrapper"><span class="time">2011 - 2013</span></span> */}
                </div>
                <div className='time'>By the end of 2024</div>
                <div class="desc">
                    <li>
                        Support for the full ETH ecosystem
                    </li>
                    <li> Smart alert system </li>
                    {/* <li> Lending/borrowing Analytics </li>
                    <li> Beginning of expansion to other blockchains </li> */}
                    {/* My current employment. Way better than the position before! */}
                </div>
            </div>
        </li>

        {/* <li>
            <div class="direction-r">
                <div class="flag-wrapper">
                    <div class="flag" style={{background: 'transparent'}}>
                        <span class="gradientColor2">Secret </span>
                        <span style={{fontFamily: 'SFPro', fontSize: '30px'}}>🤫</span>
                    </div>
                </div>
                <div className='time'>2024-2025</div>
                <div class="desc">
                    <li>
                        Gamechanger
                    </li>
                    <li>
                        No words, no worries...
                    </li>
                </div>
            </div>
        </li> */}
      
    </ul>
    )
}