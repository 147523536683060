import React from 'react';
import "./style.css";
import BoxSlider from './BoxSlider';
import ResponsiveText from './ResponsiveText';
import logoApi from './assets/logo_api.webp';
import { Roadmap } from './Roadmap';

import rocket from "./assets/rocket_pink_1_2x.webp"

function LeftCard(props) {
    return (<div className='left-card card'
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: 'space-between',
                            width: props.width ? props.width : '25%',
                        }}
                    >
                        
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '0px'}}> Most popular  <br></br> on-chain metrics </h3>
                        <div>
                            <p style={{fontFamily: "SFProDisReg", fontSize: '16px', color: 'grey', width: '100%'}}> SOPR, Profits & Losses, CEX Inflow&Outflow, TVL <br></br> and more</p>
                            <h2 style={{fontFamily: 'SFProBold', margin: 0}}> 50+ </h2>
                        </div>
                    </div>)
}

function RightCard(props) {
    return (
        <div className='right-card card' 
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: 'space-between',
                            width: props.width ? props.width : '25%',
                        }}>
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Labeled  <br></br> Wallets </h3>
                        <div>
                        <p style={{fontFamily: "SFProDisReg", fontSize: '16px', color: 'grey'}}>Funds, Whales, Exchanges, OTC Desks, Top Gainers, and more</p>
                        <h2 style={{fontFamily: 'SFProBold', margin: 0}}> 10000+ </h2>
                        </div>
                    </div>
    )
}

export function ThirdSection(props) {
    return (
        <div className='third-section' style={{marginTop: "160px"}}>
            <div style={{display: 'block', width: '100%', marginLeft: 'auto', marginRight:'auto'}}>
                <div className='cards-first-layer' >
                    {props.isLaptop && <div className='left-card card'
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: 'space-between'
                        }}
                    >
                        
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '0px'}}> Most popular  <br></br> on-chain metrics </h3>
                        <div>
                            <p style={{fontFamily: "SFProDisReg", fontSize: '16px', color: 'grey', width: '100%'}}> SOPR, Profits & Losses, CEX Inflow & Outflow, TVL <br></br> and more</p>
                            <h2 style={{fontFamily: 'SFProBold', margin: 0}}> 50+ </h2>
                        </div>
                    </div> }
                    <div className='middle-card card' style={{paddingLeft: 0, paddingRight: 0, width: props.isLaptop ? '50%' : '100%'}}>
                        <div style={{height: '50%'}}>
                            <h2 style={{fontFamily: 'SFProBold', margin: 0, marginBottom: '10px'}}>Growing list of <br></br> supported tokens</h2>
                            <h3 style={{fontFamily: 'SFProMedium', padding: 0, margin: 0, marginBottom: '20px'}}> 365+</h3>
                        </div>
                        <BoxSlider />
                    </div>
                    {props.isLaptop && <div className='right-card card' 
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: 'space-between'
                        }}>
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Labeled <br></br> Wallets </h3>
                        <div>
                        <p style={{fontFamily: "SFProDisReg", fontSize: '16px', color: 'grey'}}>Funds, Whales, Exchanges, OTC Desks, Top Gainers, and more</p>
                        <h2 style={{fontFamily: 'SFProBold', margin: 0}}> 10,000+ </h2>
                        </div>
                    </div> }
                </div>
                {!props.isLaptop && !props.isMobile && 
                <div className='cards-first-layer' style={{marginTop: '1.5em'}} >
                    <LeftCard width={'50%'}/>
                    <RightCard width={'50%'}/>
                </div>
                }
                {props.isMobile &&
                    <div style={{marginTop: '1.5em', height: '300px', display: 'flex'}} >
                        <LeftCard width={'100%'}/>
                    </div>
                }
                {props.isMobile &&
                    <div style={{marginTop: '1.5em', height: '300px', display: 'flex'}} >
                        <RightCard width={'100%'}/>
                    </div>
                }
                {!props.isLaptop && <div className='cards-second-layer' style={{marginTop: '1.5em'}} >
                    <div className='left-card card' style={{width: '50%', padding: props.isMobile ? '0.5em':'2.5em'}}>
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Historical Data <br></br> via API </h3>
                    </div>
                    <div className='right-card card' style={{width: '50%', padding: props.isMobile ? '0.5em':'2.5em'}}>
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Real-time <br></br> on-chain Alerts </h3>
                    </div>
                </div>}
                <div className='cards-second-layer' style={{marginTop: '1.5em'}} >
                    {props.isLaptop &&<div className='left-card card'>
                        <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Historical Data <br></br> via API </h3>
                    </div>}
                    <div className='middle-card card' style={{paddingLeft: 0, paddingRight: 0, width: props.isLaptop? '50%' : '100%'}}>
                    {/* <h2 style={{fontFamily: 'SFProBold'}}> Cherry-pick your <br></br> set of tokens </h2> */}
                    <h2 style={{fontFamily: 'SFProBold'}}> Cherry-pick using <br></br> on-chain data </h2>
                    </div>
                    {props.isLaptop &&<div className='right-card card'>
                    <h3 style={{fontFamily: 'SFProBold', margin: 0, marginTop: '10px'}}> Real-time <br></br> on-chain Alerts </h3>
                    </div>}
                </div>
            </div>
            <div style={{marginTop: "160px", marginLeft: props.isLaptop ? '48px': '0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '70%'}}>
                <div style={{width: props.isMobile ? '100%' : '50%'}}>
                    <ResponsiveText />
                    <h4 style={{fontFamily: 'SFProDisReg', 
                        // fontSize: '24px',
                        maxWidth: '640px',
                        // color: 'white',
                        padding:0, 
                        margin:0,
                        marginTop: '40px'}}> 
                        {/* Get any on-chain data on the scale of one minute, access to real-time API or more on your demand. */}
                        Get any on-chain data with one-minute granularity, access real-time API, and more on demand.
                    </h4>
                    <div className='bottomBlock'>
                        <a href="https://forms.gle/ML8X2dZrPcxbUBZM7" class="button2" style={{ fontFamily: 'SFProMedium', marginTop: "0px"}}>CONTACT US</a>
                     </div>
                </div>
                {!props.isMobile && <div className='img-container' style={{width: '50%', height: '50%'}}>
                    <img src={logoApi} style={{height: '80%', width:'70%', objectFit: 'cover', objectPosition: '0% 0%', float: 'right'  }}/>
                </div>}
                {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={logoApi} style={{height: '400px'}}/>
                </div> */}
                {/* <div className='img-container' style={{height: '50%'}}>
                    <img src={logoApi} style={{height: '100%', width:'100%', objectFit: 'cover', objectPosition: '0% 0%'}}/>
                </div> */}
            </div>
            {props.isMobile && <div className='img-container' style={{width: '100%', height: '100%', marginTop: '40px', display: 'flex', justifyContent: 'center'}}>
                    <img src={logoApi} style={{height: '80%', width:'60%', objectFit: 'cover', objectPosition: '0% 0%'}}/>
                </div>}
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '120px', flexDirection: 'column', alignItems: 'center'}}>
                <h1 
                    // className='gradientColor2'
                    style={{
                            fontFamily: 'SFProBold',
                            display: 'inline-block',
                            lineHeight: '72px',
                            color: '#1D1A2D',
                            marginBottom: '0.35em',
                        }}
                > 
                    Roooadmap
                </h1>
                <Roadmap /> 
            </div>
            <div className="feedback">
                <div style={{padding: props.isLaptop ? '40px 80px 40px 80px': (props.isMobile ? '5px 15px 20px 15px':'20px 40px 20px 40px')}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{width: !props.isLaptop? '100%' : '50%'}}>
                    <h1 className='gradientColor2' style={{fontFamily:"SFProBold", display: 'inline-block', marginBottom: 0}}>
                        Not just analytics <br></br> platform...</h1>
                    {/* <p style={{fontFamily: 'SFPro', 
                        fontSize: props.isLaptop ? '22px': '18px',
                        maxWidth: '640px',
                        // color: 'white',
                        // fontSize: '',
                        padding:0, 
                        margin:0,
                        marginTop: '40px',
                        marginBottom: '20px'}}> We are tiny team with giant ambitious</p> */}
                    <p style={{ fontFamily:'SFProDisReg', fontSize: props.isLaptop ? '22px' : '18px', margin: '2em 0'}}> <span style={{fontFamily: 'SFProMedium'}}>We are tiny team with giant ambitious</span>, who believe that the crypto space can be more transparent and accessible for community.</p>
                    {/* <p style={{fontFamily:'SFProLight', fontSize: props.isLaptop ? '18px' : '16px', margin: '0.5em 0'}}>
                     For now, the entire project is based on our endless enthusiasm and is fully bootstrapped. However, we have big plans, so we would appreciate any support!
                    </p> */}
                    {/* <p style={{fontFamily:'SFProLight', fontSize: props.isLaptop ? '18px' : '16px', margin: '0.5em 0'}}>By the way, <span style={{fontFamily: 'SFProBold'}}>we are looking for an angel investor partner</span>. If you are interested in our project, please get in touch with us!</p> */}
                    {/* <a href="https://forms.gle/ML8X2dZrPcxbUBZM7" class="button2" style={{fontSize: '20px', fontFamily: 'SFProMedium', borderRadius: '35px', marginTop: "40px"}}>B E&nbsp;&nbsp;A&nbsp;&nbsp;P A R T</a> */}
                    <a href="https://forms.gle/4WQT99NA3c6Hz1p87" class="button2" style={{fontFamily: 'SFProMedium', marginTop: "1.5em"}}>BE A PART</a>
                        {/* <br></br> If you are interested in our project and want to participate or just get to know us. <br></br> Feel free to contact us.  */}
                    </div>
                    {props.isLaptop && <div className='img-container' style={{width: '50%', height: '50%'}}>
                        <img src={rocket} style={{height: '80%', width:'70%', objectFit: 'cover', objectPosition: '50% 50%', float: 'right', borderRadius: '360px', opacity: 0.7 }}/>
                    </div>}
                    </div>
                </div>
            </div>
            
            {/* <Roadmap /> */}
        </div>
    )
}