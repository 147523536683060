import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import "./style.css";

import {
    Route,
    Routes,
  } from "react-router-dom";
import {BrowserRouter as Router} from 'react-router-dom';

import { motion, 
         useScroll, 
         useMotionValueEvent } from "framer-motion"

import { SecondSection, SecondSectionMobile } from './SecondSection';
import { ThirdSection } from './ThirdSection';

import { Footer } from './Footer';
import { TermsText, PrivacyText, CookieText } from './TermsAndConditions';

import posthog from 'posthog-js'

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
        posthog.init('phc_Y72y0JrkRWhfDei7pWzaRBbVjWVKdHC2p7ueVTSUBG5', { api_host: 'https://app.posthog.com' })
}

function NavbarTop(props) {
  return (
    <motion.div
    className='navbar'
      animate={{
        translateY: props.show ? 0 : -100,
      }}
      transition={{
        duration: 0.4,
      }}
      style={{position: !props.withoutNav ? 'sticky' : 'absolute'}}
    >
      <div className='head'>
          {/* <img src={logo} className='logo'></img> */}
          {/* {!props.withoutNav && <div className='toolbar'>
            <p className='headerNav'>Explore</p>
            <p className='headerNav'>Product</p>
            <p className='headerNav'>Roadmap</p>
          </div>} */}
        </div>
    </motion.div>
    // <motion.div 
    //   className='navbar'
    //   animate={{
    //     translateY: props.show ? 0 : -100,
    //   }}
    //   transition={{
    //     duration: 0.4,
    //   }}
    // />
  )
}

// function NavbarBottom(props) {
//   return (
//     <motion.div 
//       className='navbar'
//       animate={{
//         translateY: props.show ? 0 : -100,
//       }}
//       transition={{
//         duration: 0.4,
//       }}
//     />
//   )
// }


// function ProductTemplate() {

//   return (
//     <div className='product-template'>

//     </div>
//   )
// }


function MainBlock() {
  const [isLaptop, setIsLaptop] = useState(window.innerWidth > 950)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 450)

  const [opacityVal, setOpacity] = useState(1.0)

  const handleResize = () => {
    if (window.innerWidth < 950) {
        setIsLaptop(false)
    } else {
        setIsLaptop(true)
    }
    if (window.innerWidth < 450) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const ref = useRef(null)

  const ref2 = useRef(null)

  const [showNavbar, setShow] = useState(true)
  
  const { scrollY } = useScroll({
    target: ref,
    offset: ["start end", "end end"]
  })


  useMotionValueEvent(scrollY, "change", (latest) => {
    if ((latest/window.innerHeight) > 0.2) {
      setShow(false)
    } else {
      setShow(true)
    }
    setOpacity(1.0-1.4*(latest/window.innerHeight))
  })

  return (
    <div>
      <NavbarTop show={showNavbar} />
      {/* <div className="canvas-container">
        <SphereComponent />
      </div> */}
      <div className='first-section'>
          <motion.div className='call-to-action'
            initial={{scale: 0.4, opacity: 0.0}}
            animate={{scale: [0.5, 1.5, 1.0], opacity: [0.35, 0.5, 1.0, 1.0]}}
            transition={{duration: 1.5}}
          >
            <div className='action-phrase' style={{padding: '20px'}}>
                <motion.h1 
                    style={{color: 'white', fontFamily: "SFProBold", opacity: opacityVal, marginBottom: 0}}
                    // initial={{scale: 0.4, opacity: 0.0}}
                    // animate={{scale: 1.0, opacity: 1.0}}
                    // transition={{duration: 1.2}}
                > 
                    Blockchain <br /> Analytics Tool
                </motion.h1>
                <motion.h2 style={{fontFamily: 'SFProDisReg', color: 'white', opacity: opacityVal, fontSize: '24px'}}>
                  Efficiently process real-time blockchain data <br /> to provide accurate and timely on-chain metrics.
                </motion.h2>
                {/* Find first opportunity to <br /> make money in crypto */}
                {/* <h2 style={{fontFamily: 'SFProReg', color: 'white'}}>
                  Find first opportunity to <br /> make money in crypto
                </h2> */}
            </div>
            <div style={{display: 'flex', minWidth: '450px', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
              <div className='bottomBlock' style={{opacity: 2*opacityVal, marginTop: '40px', width: 'auto', marginTop: '20px'}}>
                
                  <a href="https://app.cherry-pick.xyz" class="button" style={{ fontFamily: 'SFProMedium', marginTop: "0px"}}>LAUNCH APP</a>
              </div>
              <div className='bottomBlock' style={{opacity: 2*opacityVal, marginTop: '40px', width: 'auto', marginTop: '20px'}}>
                  <a onClick={()=>{ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}}  class="button3" style={{ fontFamily: 'SFProMedium', marginTop: "0px", cursor: 'pointer'}}>READ MORE ↓</a>
              </div>
            </div>
            
          </motion.div>
      </div>

      <main ref={ref}>
        {isLaptop && <SecondSection />}
        {!isLaptop && <SecondSectionMobile />}
        
        <ThirdSection isLaptop={isLaptop} isMobile={isMobile}/>
        <Footer />
      </main>

    </div>
  )
}

function TermsAndConditions() {
  return (
    <div>
      <main>
        <NavbarTop show={true} withoutNav={true}/>
        <TermsText />
      </main>
    </div>
  )
}

function PrivacyPolicy() {
  return (
    <div>
      <main>
        <NavbarTop show={true} withoutNav={true}/>
        <PrivacyText />
      </main> 
    </div>
  )
}

function CookiePolicy() {
  return (
    <div>
      <main>
        <NavbarTop show={true} withoutNav={true}/>
        <CookieText />
      </main>
    </div>
  )
}



ReactDOM.render(
  <Router>
    <Routes>
                    <Route path="/" element={<MainBlock />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
    </Routes>
  </Router>
  ,
  document.getElementById("root")
)