import React, {useState, useRef } from 'react';
import "./style.css";

import arrow from "./assets/arrow.png"
// import smartmoney_slide from "./assets/ROISlide.png"
//import smartmoney_slide from "./assets/roi_slide.png"
import smartmoney_slide from "./assets/roi_slide_1_5x.webp"
import onchain_metrics_slide from "./assets/onchain_metrics_1_5x.webp"
import smart_alerts from "./assets/smart_alerts_1_5x.webp"
// import onchain_metrics_slide from "./assets/onchain_metrics_slide.png"
// import smart_alerts from "./assets/smart_alerts.png"

import { motion, 
    useScroll, 
    useMotionValueEvent, 
    useInView } from "framer-motion";


function FeatureBlock(props) {
    const [onHover, setHover] = useState(false)

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div 
            ref={ref} 
            style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"}}
        >
            <h1 
                className={ props.visible ? 'gradientColor':''}
                style={{
                        fontFamily: 'SFProBold',
                        display: 'inline-block',
                        // fontSize: '60px',
                        lineHeight: '72px',
                        // color: props.visible ? 'rgb(0, 0, 0)' :'rgb(146, 146, 161)',
                        // color: props.visible ? 'rgb(0, 0, 0)' :'rgb(146, 146, 161)',
                        color: !props.visible? 'rgba(255, 255, 255, 0.5)' : '',
                        marginBottom: '0.35em',
                       }}
            > 
                {props.header} 
            </h1>
            <motion.div
                style={{overflow: 'hidden', width: '100%', height: props.height ? props.height : '0px'}}
                // 650
                animate={{
                    height: props.visible ? (props.height? props.height : 160) : 0,
                }}
                transition={{
                    duration: 0.6
                }}
            >
                <h4 style={{
                    fontFamily: 'SFProDisReg', 
                    // fontSize: '24px',
                    maxWidth: '640px',
                    color: 'white',
                     padding:0, 
                     margin:0}}> {props.text} </h4>
                <div 
                    style={{cursor:'pointer',display: 'flex', flexDirection: 'row',alignItems: 'center'}}
                    onClick={()=>{window.location=`${props.link}`}}
                    onMouseEnter={() => {setHover(true)}}
                    onMouseLeave={() => {setHover(false)}}
                    >
                    <h4 style={{
                        fontFamily: 'SFProBold',
                        color: '#EDA6C6',
                        borderBottom: onHover ? "1.5px solid #EDA6C6": "",
                    }}>
                        {props.type ? 'ADD TO WHITELIST': 'TRY IT NOW'}
                    </h4>
                    <img src={arrow} style={{width: '14px', height: '14px', marginLeft: '10px', marginBottom: onHover? '1.5px' : '0px'}}></img>
                </div>
            </motion.div>
        </div>
    )
}


function FeatureDescription(props) {

      return (
        <div 
          style={{marginLeft: "80px"}}
        >
            <FeatureBlock 
                visible={(props.selectedFeature === 1)} 
                // header={'On-chain Analysis'} 
                // text={'Gain a cutting edge using high quality on-chain indicators. We are the first who provides such data for altcoins!'}
                header={'On-Chain Metrics'} 
                text={'Get high-quality data and signals for 400+ altcoins, not just BTC and ETH, to understand market sentiment better'}
                link={'https://app.cherry-pick.xyz/ldo/overview'}/>
            <FeatureBlock  
                visible={(props.selectedFeature === 2)}
                // header={'Smart Money'} 
                // text={'Find top gainers and follow their trading strategies. Invest in the next moonshot project with early investors!'}
                header={'Smart Money'} 
                text={'Identify and follow successful wallet strategies. Learn from early investors to discover new opportunities'}
                link={'https://app.cherry-pick.xyz/leaderboard/top-gainers-roi/ldo'}/>
            <FeatureBlock
                visible={(props.selectedFeature === 3)}
                // header={'Alerts'} 
                // text={'Create your own smart alerts. Be the first to know about the actions of whales, funds and exchanges!'}
                header={'Custom Alerts'} 
                text={'Set up personalized alerts to stay informed about important market movements and activities'}
                link={'https://forms.gle/wpQRmjNkBBAQBoD48'}
                type={'whitelist'}
                />
        </div>
      )
}


function LeftSide(props) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    console.log("VWHY in view", isInView)

    // const container = {
    //     visible: {
    //         transition: {
    //             staggerChildren: 0.025
    //         }
    //     }
    // }

    return (
        <div className='left-side' >
            <div className='buttons-div' >
                <h1 
                // className='headerButtons'
                className='gradientColor'
                ref={ref} 
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    fontFamily: 'SFProBold', 
                    // lineHeight: 1.0, 
                    lineHeight: '56px',
                     fontSize: "30px",
                     marginLeft: "80px", 
                     marginTop: "80px",
                     marginBottom: "30px"
                     }}> 
                     {/* Supercharge your trades by...  */}
                     Boost your analysis with...
                </h1>
                <FeatureDescription selectedFeature={props.selectedFeature}/>
            </div>
        </div>
    )
}

function RightSide(props) { 
    return (
        <div className='right-side'>
            <div className='product-template' style={props.snapStyle}>
                <div className='img-container'>
                    <img src={onchain_metrics_slide} style={{height: '100%', width:'100%', objectFit: 'cover', objectPosition: '0% 0%'}}/>
                </div>
                {/* <motion.img 
                    style={{

                        width: 'auto',
                        height: '100%',
                        position: 'absolute',
                        left: '100px',

                    }}
                    src={onchain_metrics} 
                    // animate={{ opacity: props.scaleY, scale: props.scaleY }}/>
                /> */}
            </div>
            <div className='product-template' style={props.snapStyle}>
                    {/* <motion.img 
                    style={{
                        width: '1200px',
                        height: '600px',
                        // objectFit: 'cover',
                        display: 'inline',
                        border: '1px solid #FFFFFF',
                        borderRadius: '10px',
                        // opacity: '0.2',
                        background: "linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%)",
                    }}
                    src={smartmoney_detect} 
                    /> */}
                    <div className='img-container'>
                        <img src={smartmoney_slide} style={{height: '100%', width:'100%', objectFit: 'cover', objectPosition: '0% 0%'}}/>
                    </div>
            </div>
            <div className='product-template' style={props.snapStyle}>
                    <motion.div 
                        className='img-container'
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1.5 }}
                    >
                        <img src={smart_alerts} style={{height: '100%', width:'100%',  objectFit: 'cover', objectPosition: '0% 0%'}}/>
                    </motion.div>
            </div>
        </div>
    )
}

export function SecondSection(props) {
    const ref = useRef(null)
    const [snapScroll, setSnapScroll] = useState(false)

    const [selectedFeature, setFeature] = useState(0)

    const [scaleProp, setScaleProp] = useState(1.0)

    const snapStyle = {
        'scrollSnapAlign': snapScroll ? 'center': 'none',
    }

    const { scrollY } = useScroll({
        target: ref,
        offset: ["end end", "start start"]
    })

    function selectedPage(coef) {
        if ((coef >= 0.8) && (coef <= 1.4)) {
            return 1
        } else if ((coef >= 1.6) && (coef <= 2.4)) {
            return 2
        } else if ((coef >= 2.6) && (coef <= 3.2)) {
            return 3
        } else {
            return 0
        }
    }


    useMotionValueEvent(scrollY, "change", (latest) => {
        console.log("Page scroll 2: ", latest, 
                                    window.innerHeight, 
                                    (latest/window.innerHeight), 
                                   snapScroll,
                                    window.materialGreen)
        console.log(selectedFeature)
        if ((latest/window.innerHeight) > 0.6) {
            window.materialGreen.opacity = 0.1;
        } else {
            window.materialGreen.opacity = 0.6 - (latest/window.innerHeight)*0.83;
        }
        setScaleProp(1-Math.abs(1-(latest/window.innerHeight)))
        const newSelectedFeature = selectedPage(latest/window.innerHeight)
        if (selectedFeature !== newSelectedFeature) {
            setFeature(newSelectedFeature)
        }
        if ((latest/window.innerHeight > 1.0) && (latest/window.innerHeight < 3.0)) {
            if(!snapScroll) {
                setSnapScroll(true)
            }
        } else {
            if(snapScroll) {
                setSnapScroll(false)
            }
        }
    })

    return (
        <motion.div 
            className='second-section'
            ref={ref}
            initial={false}
            animate={{stdDeviation: scaleProp*1}}
            >
            <LeftSide selectedFeature={selectedFeature} />
            <RightSide scaleY={scaleProp} snapStyle={snapStyle}/>   
        </motion.div>
    )
} 

export function SecondSectionMobile(props) {
    const ref = useRef(null)
    const [scaleProp, setScaleProp] = useState(1.0)

    const { scrollY } = useScroll({
        target: ref,
        offset: ["end end", "start start"]
    })

    useMotionValueEvent(scrollY, "change", (latest) => {
        console.log("Page scroll 2: ", latest, 
                                    window.innerHeight, 
                                    (latest/window.innerHeight), 
                                    window.materialGreen)
        if ((latest/window.innerHeight) > 0.6) {
            window.materialGreen.opacity = 0.1;
        } else {
            window.materialGreen.opacity = 0.6 - (latest/window.innerHeight)*0.83;
        }
    })

    return (
        <motion.div className='second-section' 
        ref={ref}
        initial={false}
        animate={{stdDeviation: scaleProp*1}}>
            <div>
            <h1 
                // className='headerButtons'
                className='gradientColor'
                style={{
                    // transform: isInView ? "none" : "translateX(-200px)",
                    // opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    fontFamily: 'SFProBold', 
                    // lineHeight: 1.0, 
                    display: 'inline-block',
                    lineHeight: '56px',
                     fontSize: "30px",
                     marginBottom: "30px"
                     }}> 
                     {/* Supercharge your trades by...  */}
                     Boost your analysis with...
                     </h1>
            </div>
            <div>
                <FeatureBlock 
                visible={true} 
                // header={'On-chain Analysis'} 
                // text={'Gain a data edge using high quality on-chain metrics and signals on 40+ Altcoins which was previously only available for BTC and ETH on other platforms'}
                header={'On-Chain Metrics'} 
                text={'Get high-quality data and signals for 400+ altcoins, not just BTC and ETH, to understand market sentiment better'}
                link={'https://app.cherry-pick.xyz/ldo/overview'}
                height={'auto'}/>
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center', marginTop: '20px'}}>
                    <img src={onchain_metrics_slide} style={{width: '80%'}}/>
                </div>
            </div>
            <div>
                <FeatureBlock 
                visible={true} 
                // header={'Smart Money'} 
                // text={'Find top gainers and follow their trading strategies. Invest in the next moonshot project with early investors!'}
                header={'Smart Money Insights'} 
                text={'Identify and follow successful wallet strategies. Learn from early investors to discover new opportunities'}
                link={'https://app.cherry-pick.xyz/leaderboard/top-gainers-roi/ldo'}
                height={'auto'}/>
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center', marginTop: '20px'}}>
                    <img src={smartmoney_slide} style={{width: '80%'}}/>
                </div>
            </div>
            <div>
                <FeatureBlock 
                visible={true} 
                // header={'Alerts'} 
                // text={'Get to the root of the market situation. Create your own alerts based on the filters of the labeled transaction classes.'}
                header={'Custom Alerts'} 
                text={'Set up personalized alerts to stay informed about important market movements and activities'}
                link={'https://forms.gle/wpQRmjNkBBAQBoD48'}
                type={'whitelist'}
                height={'auto'}/>
                <div style={{display: 'flex', alignItems:'center', justifyContent:'center', marginTop: '20px'}}>
                    <img src={smart_alerts} style={{width: '80%'}}/>
                </div>
            </div>
            
            
        </motion.div>
    )
}