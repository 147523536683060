import React from 'react';

import "./terms.css"

export function TermsText(props) {
    return (
        <div className='document'>
            <h1>Terms and conditions</h1>
            <p>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the cherry-pick.xyz website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and this Website operator (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</p>
            <h2>Accounts and membership</h2>
            <p>If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.</p>
            <h2>Subscription Terms</h2>
            <p>The platform provides multiple subscription tariffs, each offering distinct access levels to our features, as detailed on the Pricing page. Subscriptions are billed on a monthly basis without automatic renewal. Cancellations do not warrant a refund; however, subscribers retain access until the subscription term concludes. Details regarding active subscriptions are accessible through the User Account page. Subscription payments are accepted via credit card. For further assistance or inquiries, contact us at info@cherry-pick.xyz.</p>
            <h2>Links to other resources</h2>
            <p>Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any other off-site resources is at your own risk.</p>
            <h2>Changes and amendments</h2>
            <p>We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our discretion. When we do, we will send you an email to notify you. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>

<p>An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.</p>
            <h2>Acceptance of these terms</h2>
            <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services. This terms and conditions policy was created with the help of WebsitePolicies.com</p>
            <h2>Website Ownership</h2>
            <p>This website, cherry-pick.xyz, is owned and operated by Artem Shafarostov, a sole professional registered under a freelance license in Dubai, UAE. The license details are as follows: License No. is 104807, Legal Status is Sole Professional, Business Unit is Dubai Internet City. The registered business address is Ground Floor, Building 16, Dubai Internet City, Dubai, United Arab Emirates. For any legal inquiries or correspondence, please contact via the provided contact information on our website.</p>
            {/* <p>This website, cherry-pick.xyz, is owned and operated by Elizaveta Dmitrievna Lazareva, a sole proprietor registered under Russian law. Her Taxpayer Identification Number (TIN) is 773461536579, and the Primary State Registration Number of the Sole Proprietor (PSRNSP) is 317774600510740. The registered business address is 123060, Moscow, Marshal Sokolovsky Street, Building 10, Building 1, Apartment 23. For any legal inquiries or correspondence, please contact via the provided contact information on our website.</p> */}
            <h2>Contacting us</h2>
            <p>If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:</p>

<p>info@cherry-pick.xyz</p>

<p>This document was last updated on May 23, 2024</p>
{/* <p>This document was last updated on February 19, 2024</p> */}
        </div>
    )
}

export function PrivacyText(props) {
    return (
        <div className='document'>
            <h1>Privacy policy</h1>
            <p>We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the cherry-pick.xyz website (“Website” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.</p>
            <p>This Policy is a legally binding agreement between you (“User”, “you” or “your”) and this Website operator (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</p>
            <h2>Data Controller Information</h2>
            <p> The controller of data processing for the purposes of this privacy policy is <b>Artem Shafarostov, operating as a Sole Professional under a freelance license in Dubai UAE</b>.  The license details are as follows: License No. is 104807, Legal Status is Sole Professional, Business Unit is Dubai Internet City. The registered business address is Ground Floor, Building 16, Dubai Internet City, Dubai, United Arab Emirates. For any inquiries or concerns regarding data processing, please contact via the provided contact information on our website.</p>
            {/* <p>The controller of data processing for the purposes of this privacy policy is <b>Elizaveta Dmitrievna Lazareva, operating as a Sole Proprietor</b>. The Taxpayer Identification Number (TIN) is 773461536579, and the Primary State Registration Number of the Sole Proprietor (PSRNSP) is 317774600510740. The registered address is 123060, Moscow, Marshal Sokolovsky Street, Building 10, Building 1, Apartment 23. For any inquiries or concerns regarding data processing, please contact +7 903 008 82 18.</p> */}
            <h2>Automatic collection of information</h2>
            <p>When you open the Website, our servers automatically record information that your browser sends. This data may include information such as your device’s IP address, browser type, and version, operating system type and version, language preferences or the webpage you were visiting before you came to the Website and Services, pages of the Website and Services that you visit, the time spent on those pages, information you search for on the Website, access times and dates, and other statistics.</p>
            <p>Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular User of the system.</p>
            <h2>Payment Processing</h2>
            <p>We have introduced services requiring payment. For these transactions, we use Stripe, a third-party payment service, to process payments securely. Stripe will collect and manage your payment information, including credit card numbers and billing addresses, to complete transactions. Please be aware that we do not store payment details. Stripe adheres to the Payment Card Industry Data Security Standard (PCI-DSS) to ensure a high level of security for your financial data. For more details about their privacy practices, refer to <a href="https://stripe.com/privacy" style={{color:'pink'}}>Stripe's Privacy Policy.</a></p>
            {/* <p>We have introduced services requiring payment. For these transactions, we use with PaySelection, a third-party payment service, to process payments securely. PaySelection will collect and manage your payment information, including credit card numbers and billing addresses, to complete transactions. Please be aware that we do not store payment details. PaySelection adheres to the Payment Card Industry Data Security Standard (PCI-DSS) to ensure a high level of security for your financial data. For more details about their privacy practices, refer to PaySelection's privacy policy.</p> */}
            <h2>Collection of personal information</h2>
            <p>You can access and use the Website and Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Website, you may be asked to provide certain Personal Information (for example, your name and e-mail address).</p>
            <p>We receive and store any information you knowingly provide to us when you create an account, or fill any forms on the Website. When required, this information may include the following:</p>
            <ul>
                <li>Account details (such as user name, unique user ID, password, etc)</li>
                <li>Contact information (such as email address, phone number, etc)</li>
                <li>Basic personal information (such as name, country of residence, etc)</li>
                <li>Geolocation data of your device (such as latitude and longitude)</li>
            </ul>
            <p>You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Website. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
            <h2>Privacy of children</h2>
            <p>We do not knowingly collect any Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through the Website and Services. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child’s Personal Information from our Services.</p>
            <p>We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.</p>
            <h2>Use and processing of collected information</h2>
            <p>We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.</p>
            <p>Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Website and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.</p>
            <p>We act in the capacity of a data processor in situations when you submit Personal Information through the Website and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.</p>
            <p>In order to make the Website and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:</p>
            <ul>
                <li>Create and manage user accounts</li>
                <li>Send product and service updates</li>
                <li>Respond to inquiries and offer support</li>
                <li>Request user feedback</li>
                <li>Improve user experience</li>
                <li>Run and operate the Website and Services</li>
            </ul>
            <p>Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. We may also combine or aggregate some of your Personal Information in order to better serve you and to improve and update our Website and Services.</p>
            <p>Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
            <h2>Disclosure of information</h2>
            <p>Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Website and Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any information with unaffiliated third parties.</p>
            <p>Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes. We will share and disclose your information only with the following categories of Service Providers:</p>
            <ul>
                <li>
                Cloud computing services
                </li>
                <li>
                Data analytics services
                </li>
                <li>
                Data storage services
                
                </li>
                <li>
                User authentication services              
                </li>
                <li>
                Website hosting service providers
                </li>
            </ul>
            <p>We may also disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>
            <h2>Retention of information</h2>
            <p>We will retain and use your Personal Information for the period necessary to comply with our legal obligations, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.</p>
            <p>We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.</p>
            <h2>Cookies</h2>
            <p>Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
            <p>We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.</p>
            <h2>Data analytics</h2>
            <p>Our Website and Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Website and Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Website and Services.</p>
            <h2>Do Not Track signals</h2>
            <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit internetcookies.com</p>
            <h2>Social media features</h2>
            <p>Our Website and Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Website and Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Website and Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.</p>
            <h2>Email marketing</h2>
            <p>We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third-party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.</p>
            <p>In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.</p>
            <h2>Links to other resources</h2>
            <p>The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.</p>
            <h2>Information security</h2>
            <p>No data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</p>
            <p>As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.</p>
            <h2>Data breach</h2>
            <p>In the event we become aware that the security of the Website and Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will mail you a letter.</p>
            <h2>Changes and amendments</h2>
            <p>We reserve the right to modify this Privacy Policy at our discretion and without prior notice. Changes will be effective immediately upon posting the updated policy on our website, along with the revision date. We encourage you to regularly review this Policy to stay informed of any updates.</p>
            <h2>Acceptance of this policy</h2>
            <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services. This privacy policy was created with the help of WebsitePolicies.com</p>
            <h2>Contacting us</h2>
            <p>If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:</p>
            <p>info@cherry-pick.xyz</p>
            <p>We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.</p>
            {/* <p>This document was last updated on February 19, 2024</p> */}
            <p>This document was last updated on May 23, 2024</p>
        </div>
    )
}

export function CookieText(props) {
    return (
        <div className='document'>
            <h1>Cookie policy</h1>
            <p>This cookie policy (“Policy”) describes what cookies are and how they’re being used by the cherry-pick.xyz website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Policy is a legally binding agreement between you (“User”, “you” or “your”) and this Website operator (“Operator”, “we”, “us” or “our”). If you are entering into this Policy on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Policy, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Policy, you must not accept this Policy and may not access and use the Website and Services. You should read this Policy so you can understand the types of cookies we use, the information we collect using cookies and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies. For further information on how we use, store and keep your personal data secure, see our privacy policy.</p>
            <h2>What are cookies?</h2>
            <p>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a “session cookie”) or for multiple repeat visits (using a “persistent cookie”).</p>
            <p>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.</p>
            <p>Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting the Website and Services.</p>
            <p>Cookies may be set by the Website (“first-party cookies”), or by third parties, such as those who serve content or provide advertising or analytics services on the Website (“third party cookies”). These third parties can recognize you when you visit our website and also when you visit certain other websites.</p>
            <h2>What type of cookies do we use?</h2>
            <p>- Necessary cookies</p>
            <p>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our Website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account to access the content.</p>
            <p>- Functionality cookies</p>
            <p>Functionality cookies let us operate the Website and Services in accordance with the choices you make. For example, we will recognize your username and remember how you customized the Website and Services during future visits.</p>
            <p>- Analytical cookies</p>
            <p>These cookies enable us and third party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.</p>
            <p>- Social media cookies</p>
            <p>Third party cookies from social media sites (such as Facebook, Twitter, etc) let us track social network users when they visit or use the Website and Services, or share content, by using a tagging mechanism provided by those social networks.</p>
            <p>These cookies are also used for event tracking and remarketing purposes. Any data collected with these tags will be used in accordance with our and social networks’ privacy policies. We will not collect or share any personally identifiable information from the user.</p>
            <h2>What are your cookie options?</h2>
            <p>If you don’t like the idea of cookies or certain types of cookies, you can change your browser’s settings to delete cookies that have already been set and to not accept new cookies. Visit internetcookies.com to learn more about how to do this.</p>
            <p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features the Website and Services offer.</p>
            <h2>Changes and amendments</h2>
            <p>We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>
            <p>An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.</p>
            <h2>Acceptance of this policy</h2>
            <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services. This cookie policy was created with the help of https://www.websitepolicies.com/cookie-policy-generator</p>
            <h2>Contacting us</h2>
            <p>If you have any questions, concerns, or complaints regarding this Policy or the use of cookies, we encourage you to contact us using the details below:</p>
            <p>info@cherry-pick.xyz</p>
            <p>This document was last updated on September 5, 2023</p>
        </div>
    )
}